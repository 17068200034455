.statusBar {
    position: relative;
    width: 100%;
    height: 65px;
    box-shadow: 0 0 5px 0 #919191;
    box-sizing: border-box;
    z-index: 3;
    text-align: center;
}

#userIcon {
    height: 35px;
    float: right;
    margin-right: 10px;
}

#userIcon img {
    height: 100%;
}

.statusBarItemLeft {
    padding: 20px;
    width: fit-content;
    height: 100%;
    box-sizing: border-box;
    float: left;
    border-right: 1px solid #64A9F8;
}

.statusBarItemRight {
    padding: 20px;
    width: fit-content;
    height: 100%;
    box-sizing: border-box;
    float: right;
    border-left: 1px solid #64A9F8;
}