.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    background-color: #000;
    z-index: 4;
}

.modal {
    position: fixed;
    top: 10%;
    left: 10%;
    box-sizing: border-box;
    width: 80%;
    height: 80%;
    background: #FFF;
    padding: 20px 40px;
    z-index: 5;
}

.modalCloseButton {
    position: absolute;
    top: -26px;
    right: -26px;
    text-align: center;
    font-size: 26px;
    z-index: 10;
}
.modalCloseButtonErply {
    position: absolute;
    top: 22px;
    right: 32px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    z-index: 10;
    cursor: pointer;
}

.modalContent {
    height: calc(100% - 20px);
}