.languageSelectContainer {
    cursor: pointer;
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    width: 75px;
    height: 35px;
    padding: 15px;
}

.languageListContainer {
    width: 300px;
    display: block;
    position: absolute;
    top: 90px;
    left: 10px;
    -webkit-box-shadow: 0 0 5px 0 #d2d2d2;
    -moz-box-shadow: 0 0 5px 0 #d2d2d2;
    box-shadow: 0 0 5px 0 #d2d2d2;
    background-color: #FFF;
    z-index: 2;
}

.languageListContainer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.triangle-with-shadow {
    width: 60px;
    height: 60px;
    position: absolute;
    overflow: hidden;
    top: -60px;
    left: 0;
}
.triangle-with-shadow:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: #FFF;
    transform: rotate(45deg);
    top: 45px;
    left: 25px;
    box-shadow: 0 0 5px 0 #d2d2d2;
    -webkit-box-shadow: 0 0 5px 0 #d2d2d2;
    -moz-box-shadow: 0 0 5px 0 #d2d2d2;
}

.languageItem {
    font-size: 20px;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    text-align: left;
    color: #000;
}

.languageItem img{
    margin: 10px;
    vertical-align: middle;
}

.languageItem span{
    margin: 10px 20px;
    vertical-align: middle;
    color: #000;
}
