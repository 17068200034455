@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
}
@font-face {
  font-family: 'Roboto Condensed';
  src: url('fonts/robotoCondensed/RobotoCondensed-Regular.ttf');
}
@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url('fonts/robotoCondensed/RobotoCondensed-Bold.ttf');
}
@font-face {
  font-family: 'RobotoCondensed-BoldItalic';
  src: url('fonts/robotoCondensed/RobotoCondensed-BlackItalic.ttf');
}
@font-face {
  font-family: 'RobotoCondensed-Italic';
  src: url('fonts/robotoCondensed/RobotoCondensed-Italic.ttf');
}

@font-face {
  font-family: 'Erply Ladna-Bold';
  src: url('fonts/erplyLadna/ErplyLadna-Bold.woff');
}
@font-face {
  font-family: 'Erply Ladna';
  src: url('fonts/erplyLadna/ErplyLadna-Regular.woff');
}

@font-face {
  font-family: 'icofont';
  src: url('fonts/icofont/icofont.ttf');
}

body {
  font-family: "Roboto Condensed", Helvetica, "Helvetica Neue", Arial, sans-serif !important;
  font-size: 14px;
}

html, body, #root, .App, .AppErply {
  height: 100%;
}

input {
  padding: 15px;
  color: #393939;
  border: 1px solid #ccc;
}

select{
  outline: none;
}

option{
  outline: none;
  box-shadow: none;
}

option:hover,
option:focus,
option:active,
option:checked {
  outline: none;
  box-shadow: none;
}

.smallPaddingLeft {
  padding-left: 15px;
}

.centerText {
  text-align: center;
}

.errorMessage{
  color: red;
}

.uppercaseText {
  text-transform: uppercase;
}

.uppercaseFirstLetterText{
  display: block;
}

.uppercaseFirstLetterText:first-letter {
  text-transform: uppercase;
}

.largeText {
  font-size: 20px;
}

.white {
  color: #FFF;
}

.icoFont {
  font-family: icofont;
  font-style: normal;
}

.blueCircleBackgroundSmall {
  background-color: #0f82c1;
  border-radius: 50%;
  padding: 6px;
  color: #FFF;
  width: 17px;
  height: 17px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

/*.scrollbar-container {
  position: absolute !important;
}*/

.capitalizeFirst {
  text-transform: none
}
.capitalizeFirst:first-letter {
  text-transform: uppercase;
}



.AppErply, .AppErply input {
  font-family: "Erply Ladna", Helvetica, "Helvetica Neue", Arial, sans-serif !important;
  font-size: 16px;
}