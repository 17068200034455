.touchToStartWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.touchToStartContainer {
    margin-top: 110px;
}

.touchToStartContainer img {
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    display: block;
}

.touchToStartContainer span{
    font-size: 48px;
    font-family: "Erply Ladna-Bold", monospace;
    font-weight: 140;
    line-height: 56px;
    width: 100%;
    text-align: center;
    display: block;
}