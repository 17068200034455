.checkMark{
    float: left;
    height: 30px;
    width: 30px;
    border: 1px solid black;
    font-size: 40px;
    margin-right: 10px;
    line-height: 0.8;
}

.checkMark .icoFont{
    margin-left: -5px;
}