.screenSaverWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    background: linear-gradient(135deg, #007AB8 0%, #02BE8A 100%);
    color: #FFF;
    z-index: 3;
}

.screenSaverContainer {
    width: fit-content;
    margin: 0 auto;
}

.screenSaverContainer span{
    text-transform: uppercase;
    font-size: 24px;
    /*font-weight: bold;*/
    width: 100%;
    text-align: center;
    display: block;
}

.greyShadowTriangle {
    opacity: 10%;
    background-color: #01496E;

    position: absolute;
    height: 100%;
    width: 200%;
    left: -50%;
    bottom: 88%;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
}

.whiteShadowTriangle {
    opacity: 30%;
    background-color: #FFFFFF;

    position: absolute;
    height: 25%;
    width: 200%;
    left: -50%;
    bottom: 100%;
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
}

.cutUnderline{
    text-decoration: none;
    background: url(underlineLeft.png), url(underlineRight.png);
    background-size: 100px 5px;
    background-repeat: no-repeat;
    background-position: left bottom, right bottom;
}