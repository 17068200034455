.alert{
    margin-bottom: 20px;
    z-index: 1250;
    padding: 13px;
    position: fixed;
    border-radius: 0;
    text-align: center;
    left: 0;
    right: 0;
    top: 65px;
}

.infoAlert{
    background-color: #fcf8e3;
    color: #c09853;
    border: 1px solid #faebcc;
}

.errorAlert{
    background-color: #f2dede;
    color: #b94a48;
    border: 1px solid #ebccd1;
}