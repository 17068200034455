.keyboardTable {
    width: 100%;
    background-color: #f0f0f0;
    font-family: RobotoCondensed-Bold;
    /*text-transform: uppercase;*/
    table-layout: fixed;
    font-size: 18px;
    line-break: unset;
}

.keyboardTable td{
    padding: 10px;
    white-space: nowrap;
    text-align: center;
}

.spaceKey {
    background-color: #e1e1e1;
    color: #e1e1e1;
    background-clip: content-box;
}

.keyboardTableErply {
    width: 100%;
    background-color: #f2f2f2;
    font-family: "Erply Ladna-Bold";
    table-layout: fixed;
    font-size: 20px;
    line-break: unset;
}

.keyboardTableErply td{
    padding: 10px;
    white-space: nowrap;
    text-align: center;
}

.spaceKeyErply {
    background-color: #e6e6e6;
    color: #e1e1e1;
    background-clip: content-box;
    border-radius: 15px;
    /*height: 40px;*/
}

