#leftPanel {
    position: fixed;
    width: 50%;
    height: calc(100% - 65px);
    border-right: 1px solid #ebebeb;
    box-shadow: 1px 0 5px -2px #ccc;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

#leftPanelFullScreen {
    position: fixed;
    width: 100%;
    height: calc(100% - 65px);
    border-right: 1px solid #ebebeb;
    box-shadow: 1px 0 5px -2px #ccc;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

#rightPanel {
    position: fixed;
    left: 50%;
    width: 50%;
    height: calc(100% - 65px);
    font-size: 35px;
}

#rightPanelFullscreen {
    position: fixed;
    left: 0;
    width: 100%;
    height: calc(100% - 65px);
    font-size: 35px;
    z-index: 2;
    background: white;
}

.basketHeader {
    min-height: 60px;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
}

.italic {
    font-style: italic;
}

.zIndex6 {
    z-index: 6 !important;
}

.selectInput {
    padding: 10px;
}

.fullWidth {
    width: 100%;
}

#productList {
    margin: 0;
    padding: 0;
    border-top: 1px solid #ebebeb;
    position: relative;
    overflow-y: scroll;
    scroll-behavior: smooth;
    width: calc(100% + 17px);
    box-sizing: content-box;
    flex-grow: 1
}

.ps__rail-y {
    opacity: 0.9 !important;
}

.responsiveTable {
    margin: 0;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
}

.responsiveTable td{
    padding: 6px 14px;
    text-align: center;
}

.responsiveTable td:empty{
    padding: 0;
}

.responsiveTableErply {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    border-collapse: collapse;
}

.responsiveTableErply td{
    padding: 10px 20px;
    text-align: center;
}

.responsiveTableErply th {
    padding: 13px;
}

.borderBottomTd td {
    border-bottom: 1px solid #ebebeb;
}

.productLineOdd {
    background-color: #F9F9F9;
}

.productLineNameColumn {
    width: 45%;
    text-align: left !important;
    font-weight: bold;
}

.productLinePriceColumn {
    font-size: 18px;
}

.productLineSmall {
    zoom: 0.9;
}

.productLineSmall td {
    padding: 0 18px;
}

.roundBackground {
    border-radius: 20px;
    padding: 5px 20px;
    font-weight: bold;
    width: max-content;
}

.grayBackground{
    background-color: #ededed;
}

.blueBackground {
    background-color: #0082C3;
    color: #ffffff;
}

.unitPrice {
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
}

.unitPriceErply {
    font-weight: normal;
    font-style: italic;
    color: #808080;
    line-height: 1.5;
}

.bottom {
    position: fixed;
    width: 50%;
    bottom: 0;
}

.productListTotals {
    font-style: italic;
    background-color: #f0f0f0;
    font-size: 24px;
}

.productListTotals .responsiveTable td:last-child{
    text-align: right;
}

.productListTotalsErply {
    background-color: #3D94F6;
    font-size: 24px;
    width: 100%;
    height: 78px;
}

.documentTable th{
    padding: 20px;
}

.documentTable td{
    font-weight: normal;
    height: 30px;
}

.totalPrice {
    font-style: normal;
    background-color: #fee530;
    padding: 10px 10px 10px 60px;
    font-size: 30px;
}

.totalPriceErply {
    font-style: normal;
    font-weight: bold;
    color: #FFFFFF;
    padding: 10px;
    font-size: 48px;
}

.addProductContainer {
    padding: 10px;
    text-transform: uppercase;
    box-sizing: border-box;
    float: right;
    color: #0082C3;
    display: flex;
    height: 100%;
    align-items: center;
}

.divCenter {
    margin-top: 30%;
    margin-bottom: 30%;
    text-align: center;
}

.blueCircleBackground {
    background-color: #0f82c1;
    border-radius: 32px;
    padding: 10px;
    color: #FFF;
    width: 32px;
    height: 32px;
    font-size: 28px;
    display: inline-block;
    text-align: center;
}

.diagonalLine {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    width: 120px;
    border-top: 1px solid #9b9b9b;
}

.blueBox {
    background-color: #0f82c1;
    width: 100%;
    height: 140px;
    box-shadow: 1px 3px 4px -1px #919191;
}

.smallBlueBox:nth-child(3n+3) {
    margin-right: 0;
}

.smallBlueBox {
     background-color: #0f82c1;
     width: 30%;
     margin-right: 5%;
     margin-bottom: 20px;
     height: 100px;
     box-shadow: 1px 3px 4px -1px #919191;
     float: left;
}

.centerText {
    position: relative;
    top: calc(50% - 10px);
    font-size: 16px;
}

.smallPaymentImage {
    height: 40px;
    margin-top: 22px;
    margin-bottom: 6px;
}

.smallDialogue {
    top: calc(50% - 125px);
    left: calc(50% - 300px);
    width: 600px;
    height: 300px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.51);
}

.dialogueTitle {
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
}

.dialogueMessage {
    position: absolute;
    top: 50%;
    display: table-cell;
    vertical-align: middle;
    font-size: 18px;
    padding-right: 20px;
}

.smallDialogueMessage {
    margin-top: 20px;
    font-size: 18px;
}

.dialogueConfirmButton{
    display: inline;
}
.dialogueConfirmButton:not(:first-child){
    margin-left: 50px;
}

.dialogueConfirmButtons {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    text-align: center;
}

.dialogueSelectList {
    list-style: none;
    border: 1px solid rgb(128, 128, 128);
    padding: 0;
    margin: 0
}

.dialogueSelectList li{
    border-bottom: 1px solid lightgray;
    padding: 15px;
    cursor: pointer;
}

.dialogueSelectListErply {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    width: fit-content;
}

.dialogueSelectListErply li{
    background-color: #e6e6e6;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 34px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Erply Ladna-Bold";
    text-align: center;
    font-size: 18px;
}


.adminLeft {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 36%;
    background-color: #f0f0f0;
}

.adminRight {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 64%;
}

.boxList{
    padding: 25px 0;
}

.boxList li{
    list-style: none;
    display: block;
    position: relative;
    height: 60px;
    margin: 0 40px 20px 25px;
    background-color: #FFF;
    box-shadow: 1px 1px 3px -1px #919191;
    text-align: center;
    line-height: 60px;
    font-size: 16px;
}

.logonContent {
    padding: 10px;
}


.borderInput, .borderInput:focus {
    box-sizing: border-box;
    border: 3px solid #1383c1 !important;
}

.searchInput {
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 40px;
}

.searchInputErply {
    background-image: url("../main/images/Erply/search.png");
    background-repeat: no-repeat;
    padding-left: 40px;
    background-position-x: calc(100% - 20px);
    background-position-y: 16px;
}

.callAssistance {
    width: 60% !important;
    height: 380px !important;
    left: 20% !important;
    top: 20% !important;
}

.shadowBoxInput {
    box-shadow: 1px 1px 3px -1px #919191;
    box-sizing: border-box;
}

.moneyInputs {

}

.moneyInputs ul {
    list-style: none;
    margin-left: 10%;
}

.moneyInputs li {
    float: left;
    width: calc(50% - 24px);
    border: 1px solid #d2d2d2;
    padding: 10px;
    margin-bottom: 10px;
}

.moneyInputs li input{
    border: none;
    text-align: center;
    width: calc(100% - 100px);
    padding: 0;
}

.moneyInputs li input:focus{
    border: none !important;
    outline: none;
}

.moneyTitle{
    width: 100px;
    display: inline-block;
}

.selectedMoney {
    border: 5px solid #efad56 !important;
    padding: 6px !important;
}

.coloredPaymentRow {
    box-sizing: border-box;
    border-collapse: collapse;
}

.coloredPaymentRow td{
    padding: 5px;
}

.paymentInputTable {
    border-collapse: collapse;
    font-size: 22px;
}

.paymentInputTable input {
    border: none;
    padding: 0;
    background-color: transparent;
}

.paymentInputTable input:focus {
    border: none !important;
    outline: none;
}

.textRight{
    text-align: right;
}

.bold {
    font-weight: bold;
}

.documentList {
    margin: 1px 0 0;
    padding: 0;
    border-top: 1px solid #ebebeb;
    position: relative;
    overflow-y: scroll;
    scroll-behavior: smooth;
    box-sizing: content-box;
    margin-right: -15px;
}

@media only screen and (max-width: 1024px) {
    .documentList {
        width: 100%;
    }
}

.documentListErply {
    height: calc(100% - 394px);
    margin: 1px 0 0;
    padding: 0;
    position: relative;
    overflow-y: scroll;
    scroll-behavior: smooth;
    width: calc(100% + 17px);
    box-sizing: content-box;
}

.leftGrayBorder {
    border-left: 1px solid #E6E6E6;
}

#recentSaleList .documentList{
    height: calc(100% - 304px);
}

#pendingSaleList .documentList, #promotionList .documentList{
    height: calc(100% - 78px);
}

.voucherInputs {
    margin-left: 20px;
    margin-right: 20px;
}

.voucherInputs label {
    display: block;
}

.voucherInputs input{
    margin-top: 10px;
}

.configurationTable td{
    min-width: 50px;
}

.react-grid-item.cssTransforms {
    transition-property: none !important;
}

.productSearchTable {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
}

.productSearchTable td {
    padding: 20px 23px;
    text-align: right;
    font-size: 16px;
    min-width: 70px;
}
