.button {
    border: none;
    overflow: hidden;
    position: relative;
}

.button:focus {
    outline: none;
}

.button:hover ~ .flenche{
    opacity: 0;
}
.blueButton {
    /*background: -webkit-linear-gradient(#4eb0ed 0, #0173b9 100%);
    background: linear-gradient(#4eb0ed 0, #0173b9 100%);*/
    background-color: #0f82c1;
    box-shadow: rgba(0, 0, 0, 0.168627) 0 1px 2px 0;
    color: #FFF;
}

.yellowButton {
    background: linear-gradient(#feea40 0, #feea40 100%);
    background: -webkit-linear-gradient(#feea40 0, #feea40 100%);
    box-shadow: rgba(0, 0, 0, .0901961) 0 1px 2px 0;
    color: #000;
}

.grayButton {
    background-color: #e4e6e8;
    box-shadow: rgba(0, 0, 0, .0901961) 0 1px 2px 0;
    color: #000;
}

.greenButton {
    background-color: #5abb7c;
    color: #FFFFFF;
}

.lightBlueButton {
    background-color: #3d94f6;
    color: #FFFFFF;
}

.redButton {
    background-color: #E05B3E;
    color: #FFFFFF;
}

.darkGrayButton {
    background-color: #cccccc;
    color: #000000;
}

.quickSelectButton {
    /*background-color: #b8e2f2;*/
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    /*background-color: #E6E6E6;*/
}

.darkYellowButton {
    background-color: #f7c545;
    color: #000000;
}

.orangeButton {
    background-color: #ff9902;
    color: #0082c3;
    border: 2px solid #0082c3;
}

.transparentButton {
    background-color: transparent;
    color: #000;
}

.roundButton {
    border-radius: 5px;
}

.largeRoundButton {
    border-radius: 30px;
    font-family: "Roboto Condensed" !important;
    font-size: 14px;
    padding: 10px 20px;
}

.largeButton{
    height: 50px;
    font-size: 14px;
    padding: 12px 60px 12px 40px;
}

.largerButton{
    height: 54px;
    font-size: 18px;
    padding: 12px 40px 12px 40px;
    min-width: 220px;
}

.extralargeButton{
    height: 62px;
    font-size: 35px;
    padding: 12px 60px 12px 40px;
}

.ultraLargeButton{
    height: 80px;
    font-size: 24px;
    padding: 12px 80px 12px 80px;
}

.mediumButton{
    height: 40px;
    font-size: 14px;
    padding: 10px;
    padding-right: 30px;
}

.mediumEqualButton{
    height: 40px;
    font-size: 14px;
    padding: 20px;
    line-height: 0;
}

.largeButton:hover, .mediumButton:hover{
    /*opacity: 0.5;*/
    cursor: pointer;
}

.blueButton:active{
    opacity: 1;
    background: #0082C3;
}

.darkerBlueButton {
    color: #FFF;
    background: #0082c3;
}

.wideButton {
    width: 100%;
    height: 60px;
    font-size: 18px;
    padding: 15px 40px 15px 40px;
}

.flenche{
    mask: url("images/bg fleche.svg");
    -webkit-mask: url("images/bg fleche.svg");
    -webkit-mask-repeat: round;
    width: 35px;
    height: 44px;
    float: right;
    background-color: #fff;
    position: relative;
    right: 34px;
    top: -2px;
    opacity: 0.5;
}

.rightArrow {
    position: relative;
    top: 15px;
    right: 52px;
    height: fit-content;
    width: fit-content;
}

.flencheRight {
    position: absolute;
    height: 200%;
    width: 50px;
    right: -10%;
    top: -50%;
    bottom: -50%;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
}

.flencheSmallRightBottom{
    position: absolute;
    height: 20px;
    width: 50px;
    right: 0;
    bottom: -15px;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
}

.flencheSmallLeftBottom {
    position: absolute;
    height: 20px;
    width: 50px;
    left: -2px;
    bottom: -15px;
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
}

.transparentFlenche {
    opacity: 0.5;
    background-color: #FFF;
}

.yellowFlenche {
    background-color: #fcd846;
}

.blueFlenche {
    background-color: #0b6ea3;
}

.grayFlenche {
    background-color: #cfd1d3;
}

.shadowBox {
    box-shadow: rgba(0, 0, 0, .0901961) 3px 3px 5px 2px;
}

.checkCircle {
    color: #FFF;
    background-color: #37BEA6;
    border-radius: 20px;
    font-size: 24px;
    padding: 2px;
}

.circleBorderIcon {
    border: 1.5px solid #000;
    border-radius: 10px;
    width: 15px;
    height: 15px;
    font-size: 16px;
    line-height: 0.8;
}

.flencheBackgroundLeft{
    height: calc(100% + 20px);
    flex: 0 0 22px;
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    background-color: #1082c1;
    margin-left: -11px;
    margin-top: -10px;
}

.leftBox {
    flex: 0 0 74px;
    display: flex;
    justify-content: center;
}

.italic {
    font-style: italic;
}
